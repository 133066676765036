import React from 'react';

import { useLocale, useOnce, useQuiqChat, useStateView } from '../../hooks';
import {
  defaultSpecialPlanView,
  frictionlessPageView,
  paymentMethodView,
  paymentPlanView,
  settlementOffersView,
  splashPageView,
} from '../../state/views';

import {
  MIXPANEL_EVENTS,
  NEGOTIABLE_PLAN_TYPES,
  PAYMENT_PLAN_TYPES,
} from '../../constants';
import allMessages from './messages';

import { Button, GoBack, PaymentInfo, ProgressBar } from '../../components';
import { PrintIcon } from '../../components/icons';

import {
  printButton,
  buttonsWrapper,
  successPage,
  title,
  oldSuccessPage,
  imageContainer,
} from './styles.scss';
import { cn, logout, mixpanel } from '../../utils';
import { paths } from '../../routes';

import {
  pplp,
  paymentPlanBox,
  stepper,
  legal,
  centered,
} from '../PaymentPlanOfferLandingPage/styles.scss';
import Header from '../../components/Header';

import stepFinalSvg from '../../static/images/icons/stepFinal.svg';
import paymentSuccessSvg from '../../static/images/images/payment-success.svg';
import { Link } from 'react-router-dom';
import OldPaymentInfo from '../../components/OldPaymentInfo';
import { VARIANTS } from '../../components/ProgressBar';

const PaymentSuccessPage = () => {
  const [{ planType, paymentSchedule, referenceNumber }] =
    useStateView(paymentPlanView);
  const [{ currentBalance: settlementAmount }] =
    useStateView(settlementOffersView);
  const [{ planType: specialPlanType }] = useStateView(defaultSpecialPlanView);
  const [{ type }] = useStateView(paymentMethodView);
  const [settlementOffers] = useStateView(settlementOffersView);
  const [comesFromSplashPage] = useStateView(splashPageView);
  const [comesFromFrictionlessPage] = useStateView(frictionlessPageView);

  const { formatMessage, locale, messages } = useLocale(allMessages);

  const isSettlement = [
    PAYMENT_PLAN_TYPES.settleInFull,
    PAYMENT_PLAN_TYPES.settlementPlan,
  ].includes(planType);

  if (!type) {
    logout();
  }

  useQuiqChat({ debtId: referenceNumber });

  useOnce(() => {
    const isSettlementOffer = !!settlementAmount;
    const isSpecialOffer = !!specialPlanType;
    const isSinglePayment = paymentSchedule?.length === 1;

    let event;

    if (!planType) {
      // add payment method to promise conversion
      event = MIXPANEL_EVENTS.viewAddPaymentMethodSuccessPage;
    } else if (isSettlementOffer) {
      // settlement splash page conversion
      event = isSinglePayment
        ? MIXPANEL_EVENTS.viewSettlementOfferSuccessPagePIF
        : MIXPANEL_EVENTS.viewSettlementOfferSuccessPagePlan;
    } else if (isSpecialOffer) {
      // payment plan splash page conversion
      event = isSinglePayment
        ? MIXPANEL_EVENTS.viewSpecialOfferSuccessPagePIF
        : MIXPANEL_EVENTS.viewSpecialOfferSuccessPagePlan;
    } else {
      // generic main portal payment conversion
      event = MIXPANEL_EVENTS.viewPaymentConfirmationPage;
    }

    mixpanel.track(event, { send_immediately: true });

    if (comesFromFrictionlessPage) {
      mixpanel.track(MIXPANEL_EVENTS.viewFrictionlessSuccessPage, {
        type: isSettlement ? 'Settlement' : 'Full Balance',
      });
    }
  });

  const handlePrint = () => {
    // TODO mixpanel event
    window?.print();
  };

  return (
    <>
      {(comesFromSplashPage || comesFromFrictionlessPage) && (
        <>
          <Header hideNav />
          <div className={cn(pplp, successPage)}>
            <div className={paymentPlanBox}>
              {comesFromFrictionlessPage ? (
                <div className={stepper}>
                  <ProgressBar value={100} variant={VARIANTS.newStyle} />
                </div>
              ) : (
                <div className={stepper}>
                  <img src={stepFinalSvg} />
                </div>
              )}

              <div className={title}>
                <div className={imageContainer}>
                  <img src={paymentSuccessSvg} alt={messages.headingAlt} />
                </div>
                <h1 className={centered}>{messages.heading}</h1>
              </div>

              <PaymentInfo
                isSuccessPage
                isSettlement={isSettlement}
                settlementOffers={settlementOffers}
              />

              <div className={buttonsWrapper}>
                <Button
                  className={printButton}
                  onClick={handlePrint}
                  variant={['alternate', 'outline']}
                >
                  <PrintIcon />
                  <div>{messages.printReceipt}</div>
                </Button>
                <Link to={`${process.env.SOC_WEB_URL}?lang=${locale}`}>
                  {messages.backToHome}
                </Link>
              </div>

              <div className={legal}>
                <p>
                  {formatMessage(messages.authAgreement[type], {
                    poText: <strong>{messages.poText}</strong>,
                    businessHours: <strong>{messages.businessHours}</strong>,
                  })}
                </p>
                <p>{messages.authAgreement.print}</p>
                <p>{messages.copyrightNotice}</p>
              </div>
            </div>
          </div>
        </>
      )}
      {!(comesFromSplashPage || comesFromFrictionlessPage) && (
        <>
          <div className={oldSuccessPage}>
            <GoBack href={`${process.env.SOC_WEB_URL}?lang=${locale}`}>
              {messages.backToHome}
            </GoBack>
            <h1 className={centered}>{messages.heading}</h1>

            <section>
              <OldPaymentInfo />
            </section>

            <div className={legal}>
              <p>
                {formatMessage(messages.authAgreement[type], {
                  poText: <strong>{messages.poText}</strong>,
                  businessHours: <strong>{messages.businessHours}</strong>,
                })}
              </p>
              <p>{messages.authAgreement.print}</p>
              <p>{messages.copyrightNotice}</p>
            </div>

            <p>
              <Button
                className={printButton}
                onClick={handlePrint}
                variant={['alternate', 'outline']}
              >
                <PrintIcon />
                <div>{messages.printReceipt}</div>
              </Button>
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default PaymentSuccessPage;
