import React from 'react';
import { Link } from 'react-router-dom';

import headerLogoSrc from '../../static/images/icons/logoIcon.svg';
import headerSmallLogoSrc from '../../static/images/icons/logoMinIcon.svg';
import LanguageSelect from '../LanguageSelect';
import { paths } from '../../routes';

import {
  header,
  logo,
  menu,
  mobileLogo,
  tabletLogo,
  tabletLink,
  link,
} from './styles.scss';

import menuSrc from '../../static/images/icons/menu.svg';
import Button from '../Button';

import { useStateView } from '../../hooks';
import { menuToggledView } from '../../state/views';
import { cn } from '../../utils';

const Header = ({ hideNav = false }) => {
  const [menuToggled, setMenuToggled] = useStateView(menuToggledView);

  return (
    <>
      <div className={header}>
        {hideNav ? (
          <Link
            className={mobileLogo}
            data-mp-name='Header Logo'
            to={paths.home}
          >
            <img src={headerSmallLogoSrc} alt='Spring Oaks Capital Logo' />
          </Link>
        ) : (
          <Button
            id='menu'
            onClick={() => setMenuToggled(!menuToggled)}
            aria-label='Menu'
          >
            <img src={menuSrc} className={menu} alt='' />
          </Button>
        )}
        <Link
          data-mp-name='Header Logo'
          to={paths.home}
          className={cn(link, hideNav ? tabletLink : null)}
        >
          <img
            src={headerLogoSrc}
            className={cn(logo, hideNav ? tabletLogo : null)}
            alt='Spring Oaks Capital Logo'
          />
        </Link>
        <LanguageSelect />
      </div>
    </>
  );
};

export default Header;
