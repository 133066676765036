export default {
  en: {
    paymentPlan: 'Payment Plan',
    payInFull: 'Pay in Full',
    settleInFull: 'One Payment',
    settlementPlan: 'Custom Plan',
    paymentMethod: {
      title: 'Provide payment information',
      subtitle: 'Choose your payment method',
      accountDetails: 'Account Details',
    },
    brandName: 'Brand Name',
    originalCreditor: 'Original Creditor',
    referenceNumber: 'Reference Number',
    choose: 'OR',
  },
  es: {
    paymentPlan: 'Plan de pago',
    payInFull: 'Pague en su totalidad',
    settleInFull: 'Un pago',
    settlementPlan: 'Plan personalizado',
    paymentMethod: {
      title: 'Proporcione información de pago',
      subtitle: 'Elija su método de pago',
      accountDetails: 'Detalles de la cuenta',
    },
    brandName: 'Nombre de la marca',
    originalCreditor: 'Acreedor original',
    referenceNumber: 'Número de referencia',
    choose: 'O',
  },
};
