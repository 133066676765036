import React, { useState } from 'react';
import { func, object, oneOfType, string } from 'prop-types';
import { useLocale } from '../../hooks';
import { PAYMENT_METHODS } from '../../constants';
import { Tab } from '../../components';
import allMessages from './messages';
import { paymentMethodSelect, selected } from './styles.scss';
import { cn } from '../../utils';
import { BankIcon, CardIcon } from '../icons';
import PaymentMethodIframes from '../PaymentMethodIframes';

const PaymentMethodForm = ({
  accountId,
  showDefault,
  className,
  formClass,
  onSubmit = () => {},
  callbackFunction = () => {},
}) => {
  const { messages } = useLocale(allMessages);
  const [methodType, setMethodType] = useState(showDefault || null);

  const changeMethodType = (selectedMethodType) => {
    setMethodType(selectedMethodType);
    callbackFunction();
  };

  return (
    <div className={cn(className)}>
      <div className={cn(paymentMethodSelect, formClass)}>
        <Tab
          group='payment-method'
          id='ach'
          active={methodType === PAYMENT_METHODS.ach}
          onClick={() => changeMethodType(PAYMENT_METHODS.ach)}
        >
          <section
            className={methodType === PAYMENT_METHODS.ach ? selected : ''}
          >
            <BankIcon alt='bank' />
            <p>{messages.achTabLabel}</p>
          </section>
        </Tab>
        <Tab
          group='payment-method'
          id='card'
          active={methodType === PAYMENT_METHODS.card}
          onClick={() => changeMethodType(PAYMENT_METHODS.card)}
        >
          <section
            className={methodType === PAYMENT_METHODS.card ? selected : ''}
          >
            <CardIcon alt='credit and debit cards' />
            <p>{messages.cardTabLabel}</p>
          </section>
        </Tab>
      </div>
      <PaymentMethodIframes
        accountId={accountId}
        methodType={methodType}
        onSubmit={onSubmit}
      />
    </div>
  );
};

PaymentMethodForm.propTypes = {
  className: oneOfType([string, object]),
  accountId: string.isRequired,
  onSubmit: func,
  formClass: string,
};

export default PaymentMethodForm;
