import React, { useMemo } from 'react';
import { number, string } from 'prop-types';

import { cn } from '../../utils';
import { progressBar, newStyle } from './styles.scss';

export const VARIANTS = {
  oldStyle: 'old-style',
  newStyle: 'new-style',
};

const ProgressBar = ({
  value,
  max = 100,
  min = 0,
  className,
  variant = VARIANTS.oldStyle,
  ...props
}) => {
  const percentage = useMemo(
    () => Math.min(1, Math.max((value - min) / (max - min), 0)) * 100,
    [value, max, min]
  );

  return (
    <div
      className={cn(
        progressBar,
        className,
        variant === VARIANTS.newStyle && newStyle
      )}
      {...props}
    >
      <div style={{ width: `${percentage}%` }}></div>
    </div>
  );
};

ProgressBar.propTypes = {
  value: number.isRequired,
  max: number,
  min: number,
  className: string,
  variant: string,
};

export default ProgressBar;
