import React from 'react';
import { useLocale } from '../../hooks';
import { notice } from './styles.scss';
import informationSvg from '../../static/images/icons/informationIcon.svg';

const Notice = ({ children }) => {
  const { messages } = useLocale();

  return (
    <div className={notice}>
      <img src={informationSvg} alt={messages.notice} />
      <div>{children}</div>
    </div>
  );
};

export default Notice;
