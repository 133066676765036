import React, { useEffect, useMemo, useState } from 'react';

import { useLocale, useOnce, useParams, useStateView } from '../../hooks';
import {
  accountView,
  redirectView,
  selectedReferenceNumberView,
} from '../../state/views';

import { Button, Header } from '../../components';

import { mixpanel, redirect } from '../../utils';
import allMessages from './messages';

import { paths } from '../../routes';

import {
  disclosures,
  buttonContainer,
  disclosuresContent,
  card,
  cardHeader,
  cardBody,
  disclosuresList,
  description,
} from './styles.scss';
import { MIXPANEL_EVENTS } from '../../constants';
import { getDisclosures } from '../../service/account';
import { useRollbar } from '@rollbar/react';
import { useMatch } from 'react-router-dom';

const DisclosuresPage = () => {
  const rollbar = useRollbar();
  const { formatMessage, locale, messages } = useLocale(allMessages);
  const [redirectUrl, setRedirectUrl] = useStateView(redirectView);
  const [selectedReferenceNumber] = useStateView(selectedReferenceNumberView);
  const [account] = useStateView(accountView);
  const [{ currentCreditorName, solDisclosureID }, setDisclosure] =
    useState(account);
  const { referenceNumber } = useParams();
  const isUniqueDisclosuresRoute = !!useMatch(paths.uniqueDisclosures);

  useEffect(() => {
    if (referenceNumber && isUniqueDisclosuresRoute && !solDisclosureID) {
      getDisclosures(referenceNumber.replace(/\D+$/g, ''))
        .then((response) => setDisclosure(response))
        .catch((err) =>
          rollbar.error('Error fetching disclosures', {
            err,
            referenceNumber,
            solDisclosureID,
            isUniqueDisclosuresRoute,
          })
        );
    }
  }, [referenceNumber]);

  const solDisclosure = useMemo(() => {
    const key = `solDisclosure${solDisclosureID}`;
    return key in messages
      ? formatMessage(messages[key], { currentCreditorName })
      : false;
  }, [solDisclosureID, locale]);

  const acceptDisclosures = () => {
    mixpanel.track(MIXPANEL_EVENTS.acceptDisclosures);

    setRedirectUrl('');
    redirect(redirectUrl || paths.home);
  };

  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewDisclosuresPage));

  return (
    <div className={disclosures}>
      {!selectedReferenceNumber && <Header hideNav />}
      <div className={disclosuresContent}>
        <div className={card}>
          <div className={cardHeader} />
          <div className={cardBody}>
            <h1>{messages.header}</h1>

            {solDisclosure && (
              <p data-sol-disclosure={solDisclosureID}>{solDisclosure}</p>
            )}
            <div className={disclosuresList}>
              {messages.defaultDisclosures.map(
                ({ title, subtitle, paragraph }, index) =>
                  title || subtitle ? (
                    <div key={index}>
                      {title && <h3>{title}</h3>}
                      {subtitle && <p>{subtitle}</p>}
                      <p>{paragraph}</p>
                    </div>
                  ) : (
                    <p className={description} key={index}>
                      {paragraph}
                    </p>
                  )
              )}
            </div>
            <div className={buttonContainer}>
              <Button
                variant={['full', 'disclosure']}
                onClick={acceptDisclosures}
                aria-label={messages.continueAria}
              >
                {messages.continue}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisclosuresPage;
