export default {
  en: {
    heading: 'Congratulations!',
    printReceipt: 'Print Receipt',
    headingAlt: 'A man celebrating',
  },
  es: {
    heading: 'Felicitaciones',
    printReceipt: 'Imprimir recibo',
    headingAlt: 'Un hombre festejando',
  },
};
