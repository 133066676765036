import React, { useMemo, useState } from 'react';
import { useLocale, useOnce, useStateView } from '../../hooks';
import {
  accountsInformationView,
  redirectView,
  referenceNumbersView,
  selectedReferenceNumberView,
} from '../../state/views';
import { getAccounts, login } from '../../service/account';
import {
  dateOfBirth,
  debugConsole,
  formEventValues,
  inputValues,
  mixpanel,
  redirect,
  transformValues,
} from '../../utils';
import { paths } from '../../routes';
import { MIXPANEL_EVENTS } from '../../constants';

import {
  Button,
  GoBack,
  LegalFooter,
  MaskedInput,
  TextInput,
} from '../../components';
import allMessages from './messages';

import loginImage from '../../static/images/images/login.svg';

import {
  loginPage,
  loginForm,
  image,
  loginInput,
  error,
  loginButton,
  logoWrapper,
} from './styles.scss';
import store from '../../state/store';
import LanguageSelect from '../../components/LanguageSelect';
import SidePanels from '../../components/SidePanels';
import { getPaymentPlans } from '../../service/paymentPlan';
import { resetSessionId } from '../../utils/mixpanel';

export default () => {
  const { locale, messages } = useLocale(allMessages);
  const [, setReferenceNumbers, clearReferenceNumbers] =
    useStateView(referenceNumbersView);
  const [, setSelectedReferenceNumber, clearSelectedReferenceNumber] =
    useStateView(selectedReferenceNumberView);
  const [redirectUrl, setRedirectUrl, clearRedirectUrl] =
    useStateView(redirectView);
  const [, setAccountsInformation] = useStateView(accountsInformationView);
  const [isDisabled, setIsDisabled] = useState(true);
  const [formError, setFormError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const transform = useMemo(
    () => ({ dateOfBirth: dateOfBirth[locale] }),
    [locale]
  );

  const handleChange = (e) => {
    const credentials = inputValues(e.target.form.elements, true);
    setIsDisabled(Object.keys(credentials).length < 3);
  };

  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewLoginPage));

  const handleSubmit = async (e) => {
    setIsLoading(!isLoading);
    setIsDisabled(true);
    e.preventDefault();

    // clear login-related parts of state
    clearReferenceNumbers();
    clearSelectedReferenceNumber();
    clearRedirectUrl();

    // clear form error message
    setFormError(undefined);

    const credentials = transformValues(formEventValues(e), transform);

    // additional client-side validation here

    const formErrors = [];

    if (credentials.ssnLastFour?.length < 4) {
      formErrors.push(messages.inputErrors.ssnLastFour);
    }

    const response =
      formErrors.length === 0 &&
      (await login(credentials).catch((err) => {
        const status = err?.response?.status || 0;
        const statusType = Math.floor(status / 100);

        if (statusType !== 2) {
          // statusType 4 = client error, probably bad credentials
          // statusType 5 = server error, probably bad.
          formErrors.push(messages.formError);
          setIsLoading(false);
        }
        // set response to false
        return false;
      }));

    if (!response) {
      mixpanel.track(MIXPANEL_EVENTS.loginAttempt);
      // there was an error, don't redirect
      setFormError(formErrors);
      mixpanel.track(MIXPANEL_EVENTS.loginFail);
      return;
    }

    const storedUser = localStorage.getItem('user');
    const sameUser = storedUser && JSON.stringify(credentials) === storedUser;

    if (!storedUser) {
      localStorage.setItem('user', JSON.stringify(credentials));
    } else {
      if (!sameUser) {
        // Reset stored user
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(credentials));

        // Reset session if there's a new user login
        // mixpanel.reset();
        resetSessionId();
        mixpanel.registerSessionId();
      }
    }

    mixpanel.track(MIXPANEL_EVENTS.loginAttempt);

    // persist reference numbers associated with account

    const { accountIds } = response;
    setReferenceNumbers(accountIds);

    mixpanel.track(MIXPANEL_EVENTS.loginSuccess, { USER_ID: accountIds[0] });

    // redirect behaviors

    let newUrl = paths.homeDisclosures;

    if (accountIds.length === 1) {
      // single accountId, select it here
      const selectedReferenceNumber = accountIds[0];
      setSelectedReferenceNumber(selectedReferenceNumber);

      mixpanel.identify(selectedReferenceNumber);
      mixpanel.track(MIXPANEL_EVENTS.selectAccount, {
        referenceNumber: selectedReferenceNumber,
      });

      // redirect direct to destination and clear the redirectUrl
      setRedirectUrl('');
      newUrl = paths.homeDisclosures;
    } else {
      const accounts = await getAccounts(accountIds);
      const fullInfo = accounts.map((account) => {
        return {
          accountId: account.accountId,
          clientName: account.clientName,
          storeBrand: account.cardStoreBrand,
          currentBalance: account.remainingBalance,
        };
      });
      setAccountsInformation(fullInfo);
      // multiple accountIds
      // redirect to SelectAccountPage, then to the final destination
      setIsDisabled(false);
      newUrl = paths.selectAccount;
    }

    debugConsole.log('Login successful, redirecting to:', newUrl);
    redirect(newUrl);
  };

  return (
    <div className={loginPage}>
      <SidePanels
        leftPanelHeader={messages.journey}
        rightPanelHeader={messages.welcome}
        leftPanelImage={loginImage}
        leftPanelImgAlt={messages.leftPanelImgAlt}
      >
        <div className={loginForm}>
          <form onSubmit={handleSubmit} onChange={handleChange}>
            <TextInput
              name='lastName'
              id='lastName'
              maxLength='64'
              autoComplete='off'
              className={loginInput}
              required
              hasError={!!formError}
              {...messages.formFields.lastName}
            />
            <MaskedInput
              name='dateOfBirth'
              id='dateOfBirth'
              maxLength='10'
              autoComplete='off'
              mask='00/00/0000'
              className={loginInput}
              required
              hasError={!!formError}
              {...messages.formFields.dateOfBirth}
            />
            <MaskedInput
              name='ssnLastFour'
              id='ssnLastFour'
              maxLength='4'
              autoComplete='off'
              mask='0000'
              className={loginInput}
              required
              hasError={!!formError}
              {...messages.formFields.ssnLastFour}
            />
            <Button
              type='submit'
              variant={['large', 'full']}
              disabled={isDisabled}
              className={loginButton}
              loading={isLoading}
            >
              {messages.submitButton}
            </Button>
            {formError && <p className={error}>{messages.formError}</p>}
          </form>
          <LegalFooter />
        </div>
      </SidePanels>
    </div>
  );
};
