import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  useLocale,
  useOnce,
  useParams,
  useQuiqChat,
  useStateView,
} from '../../hooks';
import {
  accountView,
  defaultSpecialPlanView,
  negotiatedPlanView,
  paymentPlanView,
  selectedPlanTypeView,
  shortLinkRedirectView,
  splashPageView,
} from '../../state/views';
import { getSpecialPaymentPlan } from '../../service/landingPage';
import { Link } from 'react-router-dom';
import { paths } from '../../routes';
import {
  MIXPANEL_EVENTS,
  NEGOTIABLE_PLAN_TYPES,
  PAYMENT_FREQUENCIES,
  PAYMENT_PLAN_TYPES,
  PAYMENT_PLAN_MIXPANEL_TYPES,
  PAYMENT_FREQUENCY_MAPPINGS,
} from '../../constants';
import allMessages from './messages';
import {
  Button,
  CreditDisclosure,
  CurrencyInput,
  Datepicker,
  OfferHeader,
  PaymentInfo,
  PaymentSummary,
  PlanDetailBox,
  RepaymentDateDropdown,
  Spinner,
  Step,
  Stepper,
  UnverifiedPhoneLogin,
  Variant,
} from '../../components';
import {
  pplp,
  paymentPlanBox,
  planTag,
  legal,
  stepper,
  paymentDatePicker,
  dropdownDate,
  leftInfo,
  rightInfo,
  hasBackArrow,
  disclosureBorder,
  noScroll,
} from './styles.scss';
import confetti from 'canvas-confetti';
import {
  cn,
  formatCurrency,
  mixpanel,
  paymentPlanFromPlanOption,
  redirect,
} from '../../utils';
import Header from '../../components/Header';
import RepaymentFrequencyBoxes from '../../components/RepaymentFrequencyBoxes';
import { negotiatePaymentPlan } from '../../service/paymentPlan';

import InitialStep from './Steps/InitialStep';
import SetupPaymentStep from './Steps/SetupPaymentStep';
import PaymentMethodStep from './Steps/PaymentMethodStep';
import ProposePlanStep from './Steps/ProposePlanStep';
import SetupCustomPaymentStep from './Steps/SetupCustomPaymentStep';
import AcceptCustomPlanStep from './Steps/AcceptCustomPlanStep';

const PAYMENT_TYPES = {
  paymentPlan: 'paymentPlan',
  customPlan: 'customPlan',
  payInFullPlan: 'payInFullPlan',
};

const PaymentPlanOfferLandingPage = () => {
  const { formatMessage, messages } = useLocale(allMessages);
  const { referenceNumber } = useParams();

  const [proposedAmount, setProposedAmount] = useState(null);
  const [proposedFrequency, setProposedFrequency] = useState(1);

  const [{ verified }] = useStateView(shortLinkRedirectView);
  const [{ accountholderFirstName }] = useStateView(accountView);
  const [negotiatedPlan, setNegotiatedPlan] = useStateView(negotiatedPlanView);
  const [paymentPlan, setPaymentPlan, clearPaymentPlan] =
    useStateView(paymentPlanView);
  const [defaultSpecialPlan, setDefaultSpecialPlan] = useStateView(
    defaultSpecialPlanView
  );
  const [selectedPlanType, setSelectedPlanType] =
    useStateView(selectedPlanTypeView);
  const [, setComesFromSplashPage] = useStateView(splashPageView);

  if (!referenceNumber) {
    redirect(paths.specialOfferError);
  }

  const planType = NEGOTIABLE_PLAN_TYPES.specialPaymentPlan;

  useOnce(() =>
    mixpanel.track(MIXPANEL_EVENTS.viewPaymentPlanOfferLandingPage)
  );

  useQuiqChat(
    {
      firstName: accountholderFirstName,
      debtId: referenceNumber,
    },
    verified
  );

  const stepperRef = useRef();

  useEffect(() => {
    setComesFromSplashPage(true);
    // fetch default plan if referenceNumber is set and we haven't yet
    if (referenceNumber === defaultSpecialPlan?.referenceNumber) return;

    negotiatePaymentPlan({
      referenceNumber,
      proposedAmount: null,
      proposedFrequency: null,
      planType,
    }).then((data) => {
      setNegotiatedPlan(data);
      const plan = paymentPlanFromPlanOption(
        data,
        PAYMENT_FREQUENCIES.monthly,
        planType
      );
      setDefaultSpecialPlan({
        ...plan,
        totalPaymentAmount: data.currentBalance,
        referenceNumber,
      });
    });
  }, [referenceNumber]);

  // event handlers

  const handleSubmitPlan = (proposedAmount, selectedDate) => {
    if (selectedPlanType === PAYMENT_TYPES.customPlan) {
      setProposedAmount(null);

      let proposedAmountApproved = false;
      let additionalEventProps = {};

      return negotiatePaymentPlan({
        referenceNumber,
        proposedAmount,
        proposedFrequency,
        planType,
      })
        .then((data) => {
          setNegotiatedPlan(data);

          const frequency = PAYMENT_FREQUENCY_MAPPINGS[proposedFrequency];
          const plan = paymentPlanFromPlanOption(data, frequency, planType);

          proposedAmountApproved = data.proposedAmountApproved;

          if (proposedAmountApproved)
            setPaymentPlan({
              ...plan,
              referenceNumber,
              proposedAmountApproved,
              totalPaymentAmount: data.currentBalance,
              ...selectedDate,
            });

          const {
            numPayments: termsOffered,
            standardPaymentAmount,
            lastPaymentAmount,
          } = plan;

          additionalEventProps = {
            termsOffered,
            standardPaymentAmount,
            lastPaymentAmount,
          };

          return proposedAmountApproved;
        })
        .catch((e) => {
          proposedAmountApproved = false;
          setPaymentPlan({ proposedAmountApproved });
          return proposedAmountApproved;
        })
        .finally(() => {
          setProposedAmount(proposedAmount);

          mixpanel.track(MIXPANEL_EVENTS.proposeSpecialPaymentPlan, {
            currentBalance: defaultSpecialPlan.totalPaymentAmount,
            debtId: referenceNumber,
            proposedAmountApproved,
            proposedAmount,
            proposedFrequency: PAYMENT_FREQUENCY_MAPPINGS[proposedFrequency],
            ...additionalEventProps,
          });

          proposedAmountApproved && stepperRef.current.goToStep(4);
        });
    } else {
      setPaymentPlan({ ...paymentPlan, ...selectedDate });
      stepperRef.current.goToStep(5);
    }
  };

  const handleClickAcceptDefault = () => {
    mixpanel.track(MIXPANEL_EVENTS.acceptDefaultSpecialOffer, {
      ...defaultSpecialPlan,
    });
    setSelectedPlanType(PAYMENT_TYPES.paymentPlan);
    setPaymentPlan({ ...defaultSpecialPlan, proposedAmountApproved: true });
  };

  const handleClickPayInFull = () => {
    mixpanel.track(MIXPANEL_EVENTS.payFullSpecialOfferBalance, {
      totalPaymentAmount: defaultSpecialPlan.totalPaymentAmount,
    });

    setPaymentPlan.update({
      planType: PAYMENT_PLAN_TYPES.payInFull,
      numPayments: 1,
      referenceNumber,
      proposedAmountApproved: true,
      standardPaymentAmount: defaultSpecialPlan.totalPaymentAmount,
      totalPaymentAmount: defaultSpecialPlan.totalPaymentAmount,
    });

    setSelectedPlanType(PAYMENT_TYPES.payInFullPlan);
  };

  const handleClickCreatePlan = () => {
    setSelectedPlanType(PAYMENT_TYPES.customPlan);
    mixpanel.track(MIXPANEL_EVENTS.createCustomSpecialOffer);
  };

  const onShowInitialStep = () => {
    setProposedFrequency(1);
    clearPaymentPlan();
  };

  // render

  const { initial, createPlan, paymentDate, paymentMethod } = messages.steps;

  return (
    <>
      <Header hideNav />
      <UnverifiedPhoneLogin />
      <div className={verified ? pplp : cn(pplp, noScroll)}>
        {defaultSpecialPlan.planType ? (
          <>
            <Stepper
              name='Payment Plan Landing Page'
              ref={stepperRef}
              className={paymentPlanBox}
            >
              {/* initial step */}
              <Step name='Initial offer' onStepShow={onShowInitialStep}>
                <InitialStep
                  initial={initial}
                  referenceNumber={referenceNumber}
                  paymentPlan={paymentPlan}
                  defaultSpecialPlan={defaultSpecialPlan}
                  handleClickAcceptDefault={handleClickAcceptDefault}
                  handleClickCreatePlan={handleClickCreatePlan}
                  handleClickPayInFull={handleClickPayInFull}
                />
              </Step>

              {/* propose plan step */}
              <Step name='Create plan'>
                <ProposePlanStep
                  defaultSpecialPlan={defaultSpecialPlan}
                  paymentPlan={paymentPlan}
                  proposedAmount={proposedAmount}
                  referenceNumber={referenceNumber}
                  handleSubmitPlan={handleSubmitPlan}
                />
              </Step>

              {/* setup payment date step */}
              <Step>
                {paymentPlan.proposedAmountApproved && (
                  <SetupPaymentStep
                    paymentPlan={paymentPlan}
                    referenceNumber={referenceNumber}
                    proposedFrequency={proposedFrequency}
                  />
                )}
              </Step>

              {/* payment method step */}
              <Step>
                <PaymentMethodStep
                  referenceNumber={referenceNumber}
                  paymentPlan={paymentPlan}
                  currentBalance={defaultSpecialPlan.totalPaymentAmount}
                  stepperRef={stepperRef}
                  selectedPlanType={selectedPlanType}
                />
              </Step>

              {/* setup custom plan step */}
              <Step>
                {paymentPlan.proposedAmountApproved && (
                  <SetupCustomPaymentStep
                    paymentPlan={paymentPlan}
                    currentBalance={defaultSpecialPlan.totalPaymentAmount}
                    referenceNumber={referenceNumber}
                    proposedFrequency={proposedFrequency}
                  />
                )}
              </Step>

              {/* custom plan acceptance step */}
              <Step>
                {paymentPlan.proposedAmountApproved && (
                  <AcceptCustomPlanStep
                    referenceNumber={referenceNumber}
                    paymentPlan={paymentPlan}
                    currentBalance={defaultSpecialPlan.totalPaymentAmount}
                  />
                )}
              </Step>

              <div className={legal}>
                <CreditDisclosure allMessages={allMessages} />
                <p className={disclosureBorder}>
                  {messages.miniMiranda}{' '}
                  <Link data-mp-name='Know Your Rights' to={paths.disclosures}>
                    {messages.knowYourRights}
                  </Link>
                </p>
                <p>{messages.copyrightNotice}</p>
              </div>
            </Stepper>
          </>
        ) : (
          <Spinner variant='large' />
        )}
      </div>
    </>
  );
};

export default PaymentPlanOfferLandingPage;
