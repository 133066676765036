import React from 'react';
import { Button, PaymentInfo, PlanDetailBox } from '../../../components';
import PaymentFrequencyMessage from '../../../components/PaymentFrequencyMessage';
import { useLocale, useStateView } from '../../../hooks';
import { paymentMethodView, paymentPlanView } from '../../../state/views';

import allMessages from '../messages';
import paymentSuccessSvg from '../../../static/images/images/payment-success.svg';

import {
  paymentInfoTable,
  buttonsWrapper,
  successTitle,
  legal,
  progressBar,
  title,
} from '../styles.scss';
import { PrintIcon } from '../../../components/icons';
import { Link } from 'react-router-dom';
import { PAYMENT_PLAN_TYPES } from '../../../constants';

const SuccessPaymentStep = ({ referenceNumber, currentBalance, progress }) => {
  const { formatDate, formatMessage, locale, messages } =
    useLocale(allMessages);

  const [{ type }] = useStateView(paymentMethodView);
  const [paymentPlan, setPaymentPlan] = useStateView(paymentPlanView);

  const startDateFormat =
    paymentPlan.numPayments === 1
      ? { month: 'long', day: 'numeric' }
      : { month: 'long', day: 'numeric', year: 'numeric' };

  const startDatePayment = formatDate(
    paymentPlan.paymentStartDate,
    startDateFormat
  );
  const upcomingPaymentMessage = `${messages.upcomingPayment} 1 ${messages.of} ${paymentPlan.numPayments} (${messages.dueOn} ${startDatePayment})`;

  let lastPaymentMessage = '';

  if (paymentPlan.paymentSchedule && paymentPlan.paymentSchedule.length) {
    const lastDatePayment = formatDate(
      paymentPlan.paymentSchedule.at(-1).date,
      { month: 'long', day: 'numeric', year: 'numeric' }
    );
    lastPaymentMessage = `${messages.lastPayment} ${lastDatePayment}`;
  }

  const handlePrint = () => {
    // TODO mixpanel event
    window?.print();
  };

  const isPartialPayment =
    paymentPlan.planType === PAYMENT_PLAN_TYPES.paymentPartial;
  const isFullPayment =
    paymentPlan.numPayments === 1 &&
    paymentPlan.planType === PAYMENT_PLAN_TYPES.payInFull;

  return (
    <>
      <progress
        className={progressBar}
        value={progress.value / progress.total}
      />

      <div className={successTitle}>
        <img src={paymentSuccessSvg} alt='Payment Success' />
        <h2 className={title}>{messages.successStep.title}</h2>
      </div>

      <PlanDetailBox
        referenceNumber={referenceNumber}
        paymentPlan={paymentPlan}
        currentBalance={(isFullPayment || isPartialPayment) && currentBalance}
        planType={messages.plans[paymentPlan.planType]}
        upcomingPayment={upcomingPaymentMessage}
        lastPayment={lastPaymentMessage}
        paymentFrequency={<PaymentFrequencyMessage />}
        fullPayment={isFullPayment}
        showFrequency={!isPartialPayment}
        isSuccessPage
      />

      <PaymentInfo onlyTable tableClassName={paymentInfoTable} />

      <div className={buttonsWrapper}>
        <Button onClick={handlePrint} variant={['alternate', 'outline']}>
          <PrintIcon />
          <div>{messages.printReceipt}</div>
        </Button>
        <Link to={`${process.env.SOC_WEB_URL}?lang=${locale}`}>
          {messages.backToHome}
        </Link>
      </div>

      <div className={legal}>
        <p>
          {formatMessage(messages.authAgreement[type], {
            poText: <strong>{messages.poText}</strong>,
            businessHours: <strong>{messages.businessHours}</strong>,
          })}
        </p>
        <p>{messages.authAgreement.print}</p>
        <p>{messages.copyrightNotice}</p>
      </div>
    </>
  );
};

export default SuccessPaymentStep;
