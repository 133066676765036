import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
import { tableCard } from './styles.scss';

const TableCard = ({ title, data }) => {
  return (
    <div className={tableCard}>
      <h2>{title}</h2>
      <table>
        <tbody>
          {data.map(({ label, value }) => (
            <tr key={`${label}-${value}`}>
              <th>{label}</th>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

TableCard.propTypes = {
  title: string,
  data: arrayOf(
    shape({
      label: string,
      value: string,
    })
  ),
};

export default TableCard;
