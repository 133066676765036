import mixpanel from 'mixpanel-browser';
import debugConsole from './debugConsole';
import { getQueryParams } from './queryParams';
import { v4 as uuidv4 } from 'uuid';

// wrap the Mixpanel object so that we can initialize it on-demand

const isTestSession = () => {
  const isTest = getQueryParams()['is_test'] === 'true' || sessionStorage.getItem('soc_test') === 'true';
  if (isTest) sessionStorage.setItem('soc_test', 'true');
  return isTest;
};

const getSessionId = () => localStorage.getItem('sessionId');
const setSessionId = () => localStorage.setItem('sessionId', uuidv4());
const clearSessionId = () => localStorage.removeItem('sessionId');

const resetSessionId = () => {
  clearSessionId();
  setSessionId();
}

const trackerName = 'soc-portal';
const logStyle = 'color: #9c39e6';
let mp;
let initialized = trackerName in mixpanel;

const debug = (fn, first, ...rest) => fn(`%c${first}`, ...rest, logStyle);
const debugLog = (...args) => debug(debugConsole.log, ...args);
const debugGroup = (...args) => debug(debugConsole.groupCollapsed, ...args);
const logObject = props => {
  for (let key in props) {
    debugLog(`  ${key} = ${props[key]}`);
  }
};

const init = () => {
  if (!initialized) {
    mixpanel.init(
      process.env.MIXPANEL_TOKEN,
      {
        persistence: 'localStorage',
        record_sessions_percent: 100  // records 100% of all sessions
      },
      trackerName
    );
    debugLog(`Mixpanel tracker ${trackerName} initialized`);
    initialized = true;
    mp = mixpanel[trackerName];

    if (!getSessionId()) {
      setSessionId();
      mp.register({ session_id: getSessionId() });
    }
  }
};

init();

const wrappedMixpanel = {
  identify: id => {
    init();
    debugLog(`mixpanel.identify id = ${id}`);
    if (!isTestSession()) {
      mp.register({ debtId: id, session_id: getSessionId() })
      mp.people.set({ debtId: id });
      mp.identify(id);
    }
  },
  set: (props = {}, callback) => {
    init();

    const keys = Object.keys(props);

    if (keys.length === 1) {
      const key = keys[0];
      debugLog(`mixpanel.set ${key} = ${props[key]}`);
    } else {
      debugGroup(`mixpanel.set …`);
      logObject(props);      
      debugConsole.groupEnd();
    }

    if (!isTestSession()) mp.people.set(props, callback);
  },
  track: (eventName, properties = {}, options = {}, callback = () => {}) => {
    init();

    const hasProps = properties && Object.keys(properties).length > 0;
    const hasOptions = options && Object.keys(options).length > 0;

    const defaultOptions = {
      send_immediately: true
    };

    const label = `mixpanel.track '${eventName}'`;

    if (hasProps || hasOptions) {
      debugGroup(`${label} …`);
    
      if (hasProps) {
        debugLog('properties:');
        logObject(properties);
      }
      
      if (hasOptions) {
        if (hasProps) {
          debugLog('');
        }
  
        debugLog('options:');
        logObject(options);
      }

      debugConsole.groupEnd();
    } else {
      debugLog(label);
    }

    const combinedOptions = {
      ...defaultOptions,
      options
    };

    if (!isTestSession()) mp.track(eventName, properties, combinedOptions, callback);
  },
  track_links: (selector, eventName, props) => {
    init();
    debugLog(`mixpanel.track_links ${selector} ${eventName}`);
    return isTestSession() ? false : mixpanel.track_links(selector, eventName, props);
  },
  track_pageview: (props, options) => {
    init();
    const eventName = options?.event_name || '';
    debugLog(`mixpanel.track_pageview ${eventName}`);
  },
  reset: () => {
    mp.reset();
  },
  registerSessionId: () => {
    mp.register({ session_id: getSessionId() });
  }
};

const validUtmParams = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content'
];

const updateUtmParams = (newParams = getQueryParams()) => {
  if (!(window?.localStorage)) return;

  const mpStorageKey = Object.keys(localStorage).find(key => /^mp_[a-f0-9]+_mixpanel$/.test(key));
  if (!mpStorageKey) return;

  const mpStorageValue = localStorage.getItem(mpStorageKey);
  if (!mpStorageValue) return;

  let mpData;
  
  try {
    // JSON.parse throws when given invalid JSON
    mpData = JSON.parse(mpStorageValue);
  } catch (_ignore) {
    debugConsole.err('Malformed JSON in Mixpanel localStorage data', mpStorageValue);
    return;
  }

  const utmKeys = Object.keys(newParams).filter(key => validUtmParams.includes(key));
  if (utmKeys.length === 0) return;

  debugGroup(`UTM params updated …`);
  
  for (let key of utmKeys) {
    mpData[key] = newParams[key];
    debugLog(`  ${key} = ${newParams[key]}`);
  }
  
  debugConsole.groupEnd();

  localStorage.setItem(mpStorageKey, JSON.stringify(mpData));
};

export default wrappedMixpanel;
export { updateUtmParams, clearSessionId, resetSessionId };
