import React from 'react';
import useStateView from './useStateView';
import { localeView } from '../state/views';
import globalMessages from '../constants/globalMessages';
import { formatDateWithString } from '../utils/date';

const savedDelimiters = {};

const getLocaleDelimiters = (locale) => {
  if (locale in savedDelimiters === false) {
    const str = (1000.1).toLocaleString(locale);

    savedDelimiters[locale] = {
      thousand: isNaN(parseInt(str[1], 10)) ? str[1] : false,
      decimal: str[str.length - 2],
    };
  }

  return savedDelimiters[locale];
};

export default (allMessages = {}) => {
  allMessages = { ...globalMessages, ...allMessages };

  const [savedLocale] = useStateView(localeView);

  const locale = savedLocale in globalMessages ? savedLocale : 'en';

  const messages = {
    ...globalMessages[locale],
    ...(locale in allMessages ? allMessages[locale] : allMessages.en || {}),
  };

  const delimiters = getLocaleDelimiters(locale);

  return {
    locale,
    messages,
    delimiters,
    formatMessage: (message, values) => {
      // TODO optimize this with useMemo

      if (!(values && typeof values === 'object')) {
        // no templating required
        return message;
      } else {
        // values object is defined, try templating this string
        let hasNode = false;

        const templateParts = message.split(/[{}]/g).map((part, i) => {
          let resultPart = part;

          // every other part is a key
          if (i % 2) {
            const conditionalMatch = part.match(/^(.*)\?(.*)\:(.*)$/);

            if (!conditionalMatch) {
              // part is a normal key
              resultPart = values[part];
            } else {
              // part is a conditional key
              const [, operand, truePart, falsePart] = conditionalMatch;

              resultPart = values[operand] ? truePart : falsePart;

              resultPart =
                resultPart in values ? values[resultPart] : resultPart;
            }
          }

          hasNode = hasNode || typeof resultPart === 'object';
          return resultPart;
        });

        return hasNode
          ? templateParts.map((child, key) =>
              React.cloneElement(<>{child}</>, { key })
            )
          : templateParts.join('');
      }
    },
    formatDate: (date, format) => {
      date = new Date(date);

      if (typeof format === 'string') {
        return formatDateWithString(date, format);
      }

      date.setUTCHours(11);
      return date.toLocaleDateString(locale, format);
    },
  };
};
