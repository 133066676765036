import React, { useEffect, useState } from 'react';
import {
  Button,
  PaymentInfo,
  PlanDetailBox,
  Spinner,
} from '../../../components';
import PaymentFrequencyMessage from '../../../components/PaymentFrequencyMessage';
import {
  useActivePaymentPlanStatus,
  useLocale,
  useStateView,
} from '../../../hooks';
import {
  accountView,
  paymentMethodView,
  paymentPlanView,
  shortLinkRedirectView,
} from '../../../state/views';

import allMessages from '../messages';
import rightArrowIcon from '../../../static/images/icons/rightArrow.svg';
import {
  stepper,
  currentStep,
  paymentInfoTable,
  legal,
  progressBar,
  confirmButton,
  title,
  onlyMobile,
} from '../styles.scss';
import {
  cn,
  debugConsole,
  generatePaymentScheduleFromPaymentPlan,
  mixpanel,
  replaceUrl,
} from '../../../utils';

import { saveProofOfAuth } from '../../../service/paymentMethod';
import {
  MIXPANEL_EVENTS,
  PAYMENT_METHODS,
  PAYMENT_PLAN_TYPES,
} from '../../../constants';
import {
  createPaymentPlan,
  createPaymentPlanSchedule,
} from '../../../service/paymentPlan';
import { paths } from '../../../routes';

const ConfirmPaymentStep = ({
  referenceNumber,
  stepperRef,
  currentBalance,
  setCurrentStep,
  progress,
  setProgress,
}) => {
  const { formatDate, formatMessage, messages } = useLocale(allMessages);

  const [account] = useStateView(accountView);
  const [paymentPlan, setPaymentPlan] = useStateView(paymentPlanView);
  const [paymentMethod] = useStateView(paymentMethodView);
  const [shortLinkRedirect] = useStateView(shortLinkRedirectView);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isPartialPayment =
    paymentPlan.planType === PAYMENT_PLAN_TYPES.paymentPartial;
  const isFullPayment =
    paymentPlan.numPayments === 1 &&
    paymentPlan.planType === PAYMENT_PLAN_TYPES.payInFull;
  let lastPaymentMessage = isFullPayment ? 'placeholder' : '';

  const { planType } = paymentPlan;

  const { canCreatePlan, promisePlan, isPromise } =
    useActivePaymentPlanStatus();

  useEffect(() => {
    setIsLoading(true);
    if (isPromise && planType !== PAYMENT_PLAN_TYPES.paymentPartial) {
      // user is adding payment method to promise, transform the active
      // promise into a payment plan
      const promiseSchedule = promisePlan.paymentSchedule;
      const nextPaymentIndex = promiseSchedule.findIndex(
        ({ paymentDate }) => paymentDate >= promisePlan.nextPaymentDate
      );

      const remainingPromiseSchedule = promiseSchedule
        .slice(nextPaymentIndex)
        .map(({ paymentAmount, paymentDate }) => ({
          amount: paymentAmount,
          date: paymentDate,
        }));

      setPaymentPlan.update({
        // promise payment intervals and amounts are arbitrary
        planType: null,
        paymentFrequency: null,
        paymentDayOfWeek: null,
        standardPaymentAmount: null,
        paymentStartDate: new Date(remainingPromiseSchedule[0].date),
        paymentSchedule: remainingPromiseSchedule,
        referenceNumber: account.referenceNumber,
        totalPaymentAmount: promisePlan.remainingBalance,
      });
    } else {
      // generate payment schedule from user-defined payment plan
      const paymentSchedule =
        generatePaymentScheduleFromPaymentPlan(paymentPlan);
      setPaymentPlan.update({ paymentSchedule });
    }
    setIsLoading(false);
  }, [isPromise, paymentPlan]);

  const submitPayment = async () => {
    if (isSubmitting) return;

    debugConsole.log('submitting payment');
    setIsSubmitting(true);

    if (!shortLinkRedirect?.referenceNumber) {
      // raise "Click (Pay|Create Plan)" events for main portal conversions
      mixpanel.track(
        planType === PAYMENT_PLAN_TYPES.paymentPlan
          ? MIXPANEL_EVENTS.clickPaymentPlan
          : MIXPANEL_EVENTS.clickPay
      );
    }

    // record proof of auth
    const { paymentSchedule } = paymentPlan;

    if (paymentMethod.type === PAYMENT_METHODS.ach) {
      // probably don't need to await this, if not then this fn doesn't
      // need to be async
      await saveProofOfAuth({
        accountId: account.accountId,
        consumerName: paymentMethod.consumerName,
        routeNumber: paymentMethod.routingNumber,
        bankAcctLast4: paymentMethod.last4,
        accountType: paymentMethod.paymentSource,
        paymentSchedules: paymentSchedule.map(({ date, amount }) => ({
          paymentDate: date,
          currencyValue: amount,
        })),
      });
    }

    // make payment schedule request

    const request = {
      accountId: account.accountId,
      planDetails: {
        paymentSchedule,
        paymentProcessorReturn: {
          customer_id: account.accountId,
          methodType: paymentMethod.type,
          date: paymentMethod.date,
          pn_ref: paymentMethod.pnRef,
          result: paymentMethod.result,
          token: paymentMethod.token,
          card_type: paymentMethod.cardType,
          payment_source: paymentMethod.paymentSource,
          payment_last4: paymentMethod.last4,
        },
      },
    };

    let createPlanRequest = createPaymentPlanSchedule;

    switch (planType) {
      case PAYMENT_PLAN_TYPES.paymentPartial:
        const firstPayment = request.planDetails.paymentSchedule[0];
        const startDate = new Date(firstPayment.date);
        startDate.setUTCHours(11);

        delete request.planDetails.paymentSchedule;
        request.planDetails = {
          ...request.planDetails,
          paymentAmount: firstPayment.amount,
          paymentDayOfMonth: null,
          paymentDayOfWeek: null,
          planScheduleFrequency: 1,
          planStartDate: startDate.toISOString(),
          planTerm: 1,
          planType: 'partial',
        };

        createPlanRequest = createPaymentPlan;
        break;
      case PAYMENT_PLAN_TYPES.payInFull:
        request.planDetails.planType = 'PIF';
        break;
      case PAYMENT_PLAN_TYPES.settleInFull:
        request.planDetails.planType = 'SIF';
        break;
      case PAYMENT_PLAN_TYPES.settlementPlan:
        request.planDetails.planType = 'settlementSchedule';
        break;
      default:
        request.planDetails.planType = 'schedule';
        break;
    }

    createPlanRequest(request)
      .then(() => {
        // replace history state so user can't hit back button to return here
        setProgress({ ...progress, value: progress.value + 1 });
      })
      .catch((err) => {
        debugConsole.error('Payment plan create error', err);
        replaceUrl(
          shortLinkRedirect?.referenceNumber
            ? paths.specialOfferError
            : paths.accountError
        );
      })
      .finally(() => {
        setIsSubmitting(false);
        setCurrentStep('success');
        stepperRef.current.goToStep(4);
      });
  };

  if (isLoading) return <Spinner />;

  if (!isFullPayment) {
    const lastDatePayment = formatDate(
      paymentPlan.paymentSchedule.at(-1).date,
      { month: 'long', day: 'numeric', year: 'numeric' }
    );
    lastPaymentMessage = `${messages.lastPayment} ${lastDatePayment}`;
  }

  const startDatePayment = formatDate(paymentPlan.paymentStartDate, {
    month: 'long',
    day: 'numeric',
  });
  const upcomingPayment = `${messages.upcomingPayment} 1 ${messages.of} ${paymentPlan.numPayments} (${messages.dueOn} ${startDatePayment})`;

  return (
    <>
      <progress
        className={progressBar}
        value={progress.value / progress.total}
      />

      <div className={stepper}>
        <h5>{messages.payments}</h5>
        <img src={rightArrowIcon} alt='Right Arrow' />
        <h5>{messages.setupPayment.title}</h5>
        <img src={rightArrowIcon} alt='Right Arrow' />
        <h5>{messages.plans[paymentPlan.planType]}</h5>
        <img src={rightArrowIcon} alt='Right Arrow' />
        <h5 className={currentStep}>{messages.confirmStep.description}</h5>
      </div>

      <h2 className={title}>{messages.confirmStep.title}</h2>

      <PlanDetailBox
        referenceNumber={referenceNumber}
        paymentPlan={paymentPlan}
        currentBalance={(isFullPayment || isPartialPayment) && currentBalance}
        planType={messages.plans[paymentPlan.planType]}
        upcomingPayment={upcomingPayment}
        lastPayment={lastPaymentMessage}
        paymentFrequency={<PaymentFrequencyMessage />}
        fullPayment={isFullPayment}
        showFrequency={!isPartialPayment}
        isConfirmPage
      />

      <Button
        className={cn(confirmButton, onlyMobile)}
        data-action='confirm payment'
        onClick={submitPayment}
        variant={['full', 'primary']}
      >
        {messages.confirm}
      </Button>

      <PaymentInfo
        onlyTable
        isConfirmPage
        hasConfirmButton
        submitPayment={submitPayment}
        tableClassName={paymentInfoTable}
        buttonClassName={confirmButton}
      />

      <div className={legal}>
        <p>
          {formatMessage(messages.authAgreement[paymentMethod.type], {
            poText: <strong>{messages.poText}</strong>,
            businessHours: <strong>{messages.businessHours}</strong>,
          })}
        </p>
        <p>{messages.authAgreement.print}</p>
        <p>{messages.copyrightNotice}</p>
      </div>
    </>
  );
};

export default ConfirmPaymentStep;
