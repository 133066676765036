import React from 'react';
import { useLocale, useStateView } from '../../hooks';
import {
  accountView,
  defaultSpecialPlanView,
  frictionlessPageView,
  paymentMethodView,
  paymentPlanView,
  selectedPlanTypeView,
  shortLinkRedirectView,
} from '../../state/views';
import {
  CREDITOR_NOT_AVAILABLE,
  PAYMENT_METHODS,
  PAYMENT_PLAN_TYPES,
} from '../../constants';
import allMessages from './messages.js';
import {
  paymentInfo,
  paymentMethodRow,
  totalBalanceRow,
  borderBottom,
} from './styles.scss';
import { cn, formatCurrency } from '../../utils';
import PlanDetailBox from '../PlanDetailBox/index.js';
import PaymentFrequencyMessage from '../PaymentFrequencyMessage/index.js';
import Button from '../Button/index.js';
import SettlementDetailBox from '../SettlementDetailBox/index.js';

const PaymentInfo = ({
  isSuccessPage = false,
  isConfirmPage,
  hasConfirmButton,
  loading,
  submitPayment = () => {},
  buttonClassName,
  onlyTable = false,
  tableClassName,
  isSettlement,
  settlementOffers,
}) => {
  const { formatDate, messages } = useLocale(allMessages);

  const [account] = useStateView(accountView);
  const [paymentPlan] = useStateView(paymentPlanView);
  const [paymentMethod] = useStateView(paymentMethodView);
  const [selectedPlanType] = useStateView(selectedPlanTypeView);
  const [defaultSpecialPlan] = useStateView(defaultSpecialPlanView);
  const [comesFromFrictionlessPage] = useStateView(frictionlessPageView);
  const [shortLink] = useStateView(shortLinkRedirectView);

  const { clientName, cardStoreBrand } = account;

  const {
    planType,
    paymentFrequency,
    paymentDayOfWeek,
    paymentStartDate,
    paymentSchedule,
    numPayments,
    totalPaymentAmount,
  } = paymentPlan;

  const referenceNumber = paymentPlan.referenceNumber || account.accountId;

  const isPromise = !planType;

  const { cardType, consumerName, last4, paymentSource, routingNumber, type } =
    paymentMethod;

  const startDateFormat =
    numPayments === 1
      ? { month: 'long', day: 'numeric' }
      : { month: 'long', day: 'numeric', year: 'numeric' };

  const startDatePayment = formatDate(
    paymentPlan.paymentStartDate,
    startDateFormat
  );
  const upcomingPaymentMessage = `${messages.upcomingPayment} 1 ${messages.of} ${numPayments} (${messages.dueOn} ${startDatePayment})`;

  let lastPaymentMessage = '';

  if (paymentSchedule && paymentSchedule.length) {
    const lastDatePayment = formatDate(paymentSchedule.at(-1).date, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
    lastPaymentMessage = `${messages.lastPayment} ${lastDatePayment}`;
  }

  const formattedCardType =
    cardType && messages[cardType.toLowerCase()]
      ? messages[cardType.toLowerCase()]
      : cardType.charAt(0).toUpperCase() + cardType.slice(1).toLowerCase();

  const frequencyMessage = !isPromise && <PaymentFrequencyMessage />;

  const isCustomPlan = numPayments > 1;

  const isSettlementPlan = PAYMENT_PLAN_TYPES.settlementPlan === planType;

  const isFullPayment = paymentPlan.numPayments === 1;

  let upcomingPayment = '';
  let lastPayment = '';

  if (paymentSchedule?.length && paymentStartDate) {
    const startDateFormat =
      numPayments === 1
        ? { month: 'long', day: 'numeric' }
        : { month: 'long', day: 'numeric', year: 'numeric' };

    const startDatePayment = formatDate(paymentStartDate, startDateFormat);

    upcomingPayment = (
      <>
        <strong>{`1 ${messages.of} ${numPayments}`}</strong>
        {` (${messages.dueOn} `}
        <strong>{startDatePayment}</strong>
        {')'}
      </>
    );

    lastPayment = (
      <strong>
        {formatDate(paymentSchedule.at(-1).date, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })}
      </strong>
    );
  }

  const frictionlessPagePlanTypeMessage =
    selectedPlanType === PAYMENT_PLAN_TYPES.settleInFull
      ? messages.onePayment
      : selectedPlanType === PAYMENT_PLAN_TYPES.settlementPlan
      ? messages.customPlan
      : messages[selectedPlanType];

  return (
    <>
      {!onlyTable && (
        <>
          {comesFromFrictionlessPage ? (
            <PlanDetailBox
              planType={frictionlessPagePlanTypeMessage}
              referenceNumber={referenceNumber}
              paymentPlan={paymentPlan}
              paymentFrequency={frequencyMessage}
              fullPayment={isFullPayment}
              upcomingPayment={upcomingPayment}
              lastPayment={lastPayment}
              currentBalance={paymentPlan.totalPaymentAmount}
              isFrictionlessLandingPage
              isConfirmPage={isConfirmPage}
              isSuccessPage={isSuccessPage}
            />
          ) : isSettlement ? (
            <SettlementDetailBox
              showDate={isConfirmPage || isSuccessPage}
              settlementOffers={settlementOffers}
              referenceNumber={referenceNumber}
              isSettlementPlan={isSettlementPlan}
              isConfirmPage={isConfirmPage}
              isSuccessPage={isSuccessPage}
            />
          ) : (
            <PlanDetailBox
              currentBalance={
                !isCustomPlan && defaultSpecialPlan.totalPaymentAmount
              }
              planType={messages[selectedPlanType]}
              referenceNumber={referenceNumber}
              paymentPlan={paymentPlan}
              upcomingPayment={upcomingPaymentMessage}
              lastPayment={lastPaymentMessage}
              paymentFrequency={frequencyMessage}
              isSuccessPage={isSuccessPage}
              isConfirmPage={isConfirmPage}
              fullPayment={!isCustomPlan}
            />
          )}
          {hasConfirmButton && (
            <div className={buttonClassName}>
              <Button
                data-action='confirm payment'
                onClick={submitPayment}
                loading={loading}
                disabled={loading}
                variant={['small', 'primary']}
              >
                {messages.confirm}
              </Button>
            </div>
          )}
        </>
      )}

      <div className={tableClassName}>
        <table className={paymentInfo}>
          <tbody>
            {(cardStoreBrand || shortLink?.cardStoreBrand) && (
              <tr>
                <th>{messages.brandName}</th>
                <td>
                  <strong>{cardStoreBrand || shortLink.cardStoreBrand}</strong>
                </td>
              </tr>
            )}
            <tr>
              <th>{messages.originalCreditor}</th>
              <td>
                <strong>
                  {(clientName && clientName !== CREDITOR_NOT_AVAILABLE) ||
                  shortLink?.clientName
                    ? clientName || shortLink?.clientName
                    : messages.notAvailable}
                </strong>
              </td>
            </tr>
            <tr>
              <th>{messages.referenceNumber}</th>
              <td>
                <strong>{referenceNumber}</strong>
              </td>
            </tr>
            <tr>
              <th>{messages.consumerName}</th>
              <td>
                <strong>{consumerName}</strong>
              </td>
            </tr>
            <tr className={paymentMethodRow}>
              <th>{messages.paymentMethod}</th>
              <td>
                {type === PAYMENT_METHODS.ach ? (
                  <>{messages.bankAccount}</>
                ) : (
                  <>{formattedCardType}</>
                )}{' '}
                {messages.endingIn} <strong>{last4}</strong>
              </td>
            </tr>
            <tr
              className={cn(
                totalBalanceRow,
                isConfirmPage && !onlyTable && borderBottom
              )}
            >
              <th>{messages.totalBalance}:</th>
              <td>
                <strong>{formatCurrency(totalPaymentAmount)}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {hasConfirmButton && onlyTable && (
        <div className={buttonClassName}>
          <Button
            data-action='confirm payment'
            onClick={submitPayment}
            loading={loading}
            disabled={loading}
            variant={['small', 'primary']}
          >
            {messages.confirm}
          </Button>
        </div>
      )}
    </>
  );
};

export default PaymentInfo;
