import React, { useEffect, useState } from 'react';
import Spinner from '../Spinner';
import TabContent from '../TabContent';
import { getPaymentFormUrl } from '../../service/paymentMethod';
import { useLocale, useStateView } from '../../hooks';
import { paymentMethodView } from '../../state/views';
import { disclosure } from './styles.scss';
import { MIXPANEL_EVENTS, PAYMENT_METHODS } from '../../constants';
import { mixpanel } from '../../utils';

const iframeProps = {
  scrolling: 'no',
  width: '300',
};

const REPAY_URLS = process.env.REPAY_URLS.split(',').map((url) => url.trim());

const PaymentMethodIframes = ({
  accountId,
  methodType,
  onSubmit = () => {},
}) => {
  const [achFormUrl, setAchFormUrl] = useState(undefined);
  const [cardFormUrl, setCardFormUrl] = useState(undefined);
  const [, setPaymentMethod] = useStateView(paymentMethodView);

  const { messages } = useLocale();

  const formUrls = {
    ach: {
      url: achFormUrl,
      set: setAchFormUrl,
    },
    card: {
      url: cardFormUrl,
      set: setCardFormUrl,
    },
  };

  const handlePaymentForm = ({ origin, data }) => {
    const responseData = data?.paymentResponseData;

    if (!origin || REPAY_URLS.includes(origin) === false || !responseData) {
      // react-devtools will send a bunch of messages back through the iframe
      // when running a dev build, which we should ignore
      // additionally, Repay will send back a non-failure message with an origin but
      // no 'responseData' prop, these should also be ignored
      return;
    }

    if (!responseData || responseData.errors) {
      mixpanel.track(MIXPANEL_EVENTS.addPaymentMethodFailure, {
        errors: JSON.stringify(responseData.errors, null, 2),
      });
      return;
    }

    const {
      saved_payment_method: { token },
      payment_type_id: savedCardType,
      customer_id: referenceNumber,
      account_type: accountType,
      pn_ref: pnRef,
      result_details: { card_info: { type: cardType = '' } = {} } = {},
      result,
      last4,
      date,
      transit_number: routingNumber,
      name_on_check: nameOnAccount,
      name_on_card: nameOnCard,
    } = responseData;

    const isCard = !!cardType;

    const paymentMethod = {
      consumerName: isCard ? nameOnCard : nameOnAccount,
      paymentSource: isCard ? savedCardType : accountType,
      type: isCard ? PAYMENT_METHODS.card : PAYMENT_METHODS.ach,
      cardType,
      routingNumber,
      pnRef,
      result,
      last4,
      token,
      date,
      referenceNumber,
    };

    setPaymentMethod(paymentMethod);
    mixpanel.track(MIXPANEL_EVENTS.addPaymentMethodSuccess, {
      type: paymentMethod.type,
      paymentSource: paymentMethod.paymentSource,
      consumerName: paymentMethod.consumerName,
    });
    onSubmit(paymentMethod);
  };

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    window.addEventListener('message', handlePaymentForm);
    return () => window.removeEventListener('message', handlePaymentForm);
  }, []);

  useEffect(() => {
    if (!methodType || formUrls[methodType].url) {
      return;
    }

    getPaymentFormUrl({ accountId, methodType }).then((res) =>
      formUrls[methodType].set(res[0].formUrl)
    );
  }, [accountId, methodType]);

  return (
    <>
      <TabContent
        group='payment-method'
        id='card'
        active={methodType === PAYMENT_METHODS.card}
      >
        {formUrls.card.url ? (
          <iframe
            src={formUrls.card.url}
            height='1000'
            {...iframeProps}
          ></iframe>
        ) : (
          <Spinner />
        )}
      </TabContent>
      <TabContent
        group='payment-method'
        id='ach'
        active={methodType === PAYMENT_METHODS.ach}
      >
        {formUrls.ach.url ? (
          <iframe
            src={formUrls.ach.url}
            height='1220'
            {...iframeProps}
          ></iframe>
        ) : (
          <Spinner variant='medium' />
        )}
      </TabContent>
      <input type='hidden' id='next-step-input' data-next-step />
      {methodType && (formUrls.card.url || formUrls.ach.url) && (
        <p className={disclosure}>{messages.emailDisclosure}</p>
      )}
    </>
  );
};

export default PaymentMethodIframes;
