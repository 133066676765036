import React, { useMemo } from 'react';
import { arrayOf, object, oneOf, oneOfType, string } from 'prop-types';
import { cn } from '../../utils';

import {
  buttonBase,
  buttonSmall,
  buttonLarge,
  buttonFull,
  buttonOutline,
  buttonError,
  buttonAlternate,
  buttonPrimary,
  transparent,
  buttonSecondary,
  buttonDisclosure,
} from './styles.scss';
import Spinner from '../Spinner';
import { useStyleVariants } from '../../hooks';

const buttonVariants = {
  small: buttonSmall,
  large: buttonLarge,
  full: buttonFull,
  outline: buttonOutline,
  error: buttonError,
  alternate: buttonAlternate,
  primary: buttonPrimary,
  transparent: transparent,
  secondary: buttonSecondary,
  disclosure: buttonDisclosure,
};

const ButtonButton = ({ children, ...props }) => (
  <button {...props}>{children}</button>
);
const LinkButton = ({ children, ...props }) => <a {...props}>{children}</a>;

const Button = ({ className, variant, loading, children, ...props }) => {
  const classNames = useStyleVariants(buttonVariants, variant);

  const buttonChildren = useMemo(
    () => (loading ? <Spinner variant={['small', 'alternate']} /> : children),
    [loading, children]
  );

  const ButtonComponent = props.href ? LinkButton : ButtonButton;
  return (
    <ButtonComponent
      className={cn(buttonBase, classNames, className)}
      {...props}
    >
      {buttonChildren}
    </ButtonComponent>
  );
};

const buttonVariantType = oneOf(Object.keys(buttonVariants));

Button.propTypes = {
  className: oneOfType([string, object]),
  href: string,
  variant: oneOfType([buttonVariantType, arrayOf(buttonVariantType)]),
};

export default Button;
