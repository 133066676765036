import React, { useEffect, useState } from 'react';
import { useStateView } from '../../hooks';
import { paymentHistoryView, paymentPlanView } from '../../state/views';

import { progressBox, shownMobile } from './styles.scss';
import Spinner from '../Spinner';
import ActivePlan from './ActivePlan';
import PaymentHistory from '../PaymentHistory';

import Expander from '../Expander';
import AccountDetails from '../AccountDetails';

const PaymentDetailsBox = ({
  hasPlan,
  amountPaid,
  remainingBalance,
  originalBalance,
  detailsLabel,
}) => {
  const [activePaymentPlan] = useStateView(paymentPlanView);
  const [paymentStatus, setPaymentStatus] = useState({ paid: 0, total: 0 });
  const [paymentsMadeTotal, setPaymentsMadeTotal] = useState(amountPaid);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (activePaymentPlan?.paymentSchedule?.length) {
      setPaymentsMadeTotal(activePaymentPlan.completedPaymentAmount || 0);
      setPaymentStatus({
        paid: activePaymentPlan.completedPaymentCount || 0,
        total:
          activePaymentPlan.planLength +
          (activePaymentPlan.completedPaymentCount || 0),
      });
    }
    setLoading(false);
  }, [activePaymentPlan]);

  return (
    <>
      <div className={progressBox}>
        {loading ? (
          <Spinner />
        ) : (
          <ActivePlan
            hasPlan={hasPlan}
            percentage={(paymentsMadeTotal / originalBalance) * 100}
            amountPaid={paymentsMadeTotal}
            remainingBalance={remainingBalance}
            paymentStatus={paymentStatus}
          />
        )}
        <PaymentHistory summarized length={4} />
      </div>
      <Expander className={shownMobile} label={detailsLabel}>
        <AccountDetails />
      </Expander>
    </>
  );
};

export default PaymentDetailsBox;
