import React, { useEffect, useMemo, useState } from 'react';
import { any, arrayOf, bool, func, node, shape, string } from 'prop-types';

import DropdownArrow from '../../static/images/icons/DropdownArrow.js';
import itemSelected from '../../static/images/icons/dropdown-selected.svg';

import { cn } from '../../utils';
import {
  compact as compactClass,
  dropdown,
  outline as outlineClass,
  dropdownLabel,
  full,
  title,
} from './styles.scss';

const Dropdown = ({
  items,
  compact = false,
  outline = true,
  name,
  onChange = () => {},
  className,
  arrowVariant = false,
  hasSmallLabel = false,
  fullWidth = false,
  dropdownTitle,
}) => {
  const defaultIndex = useMemo(() => {
    const i = items.findIndex((e) => e.isDefault);
    return i > -1 ? i : 0;
  }, [items]);

  const [selectedIndex, setSelectedIndex] = useState(defaultIndex);
  const [isOpen, setIsOpen] = useState(false);

  const getItemLabel = (i) =>
    window.innerWidth >= 480
      ? items[i]?.label || items[i]?.value
      : hasSmallLabel
      ? items[i]?.smallLabel
      : items[i]?.label || items[i]?.value;
  const getItemKey = (item) => `dropdown-${name}-${item.value}`;

  const onClickLabel = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
    scrollResizeHandler();
  };

  const onSelectItem = (i, e) => {
    setSelectedIndex(i);
    onChange(items[i], e);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const scrollResizeHandler = () => {
    const lists = Array.from(
      document.querySelectorAll('ul[data-dropdown-items]')
    );

    for (let ul of lists) {
      const { top } = ul.getBoundingClientRect();
      const maxHeight = Math.min(window.innerHeight - top, 300);
      ul.style.maxHeight = `${maxHeight}px`;
    }
  };

  useEffect(() => {
    // select default/selected item when props change
    onSelectItem(selectedIndex);
  }, []);

  useEffect(() => {
    // if click event bubbles up to body, close dropdown
    document.addEventListener('click', closeDropdown);

    // size dropdowns to fit on window
    window.addEventListener('scroll', scrollResizeHandler);
    window.addEventListener('resize', scrollResizeHandler);
    scrollResizeHandler();

    return () => {
      document.removeEventListener('click', closeDropdown);
      window.removeEventListener('scroll', scrollResizeHandler);
      window.removeEventListener('resize', scrollResizeHandler);
    };
  }, []);

  return (
    <div
      className={cn(dropdown, outline && outlineClass, className)}
      data-is-open={isOpen ? 'true' : 'false'}
      tabIndex={0}
      aria-expanded={isOpen}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          setIsOpen(!isOpen);
        }
      }}
    >
      <input
        type='hidden'
        name={name}
        id={name}
        value={items[selectedIndex]?.value || ''}
      />
      <label htmlFor={name} onClick={onClickLabel}>
        <a className={dropdownLabel}>
          {getItemLabel(selectedIndex)}
          <DropdownArrow variant={arrowVariant} />
        </a>
      </label>
      <ul
        data-dropdown-items
        className={cn(compact && compactClass, fullWidth ? full : null)}
      >
        {dropdownTitle && <div className={title}>{dropdownTitle}</div>}
        {items.map((item, i) => (
          <li
            data-dropdown-value={item.value}
            key={getItemKey(item)}
            onClick={(e) => onSelectItem(i, e)}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                onSelectItem(i, e);
              }
            }}
          >
            <a>
              {getItemLabel(i)}
              {selectedIndex === i && <img src={itemSelected} alt='' />}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

Dropdown.propTypes = {
  items: arrayOf(
    shape({
      isDefault: bool,
      label: node,
      value: any.isRequired,
    })
  ).isRequired,
  name: string.isRequired,
  outline: bool,
  onChange: func,
  className: any,
  arrowVariant: bool,
};

export default Dropdown;
