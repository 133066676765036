import store from './store';

// auth stuff

export const sessionView = store.createView({
  name: 'session view',
  read: (state) => state.session,
  write: (session) => ({ session }),
});

// locale stuff

export const localeView = store.createView({
  name: 'locale view',
  read: (state) => state.locale,
  write: (locale) => ({ locale }),
});

// login/redirect stuff

export const referenceNumbersView = store.createView({
  name: 'account reference numbers',
  read: (state) => state.referenceNumbers,
  write: (referenceNumbers) => ({ referenceNumbers }),
});

export const selectedReferenceNumberView = store.createView({
  name: 'selected reference number',
  read: (state) => state.selectedReferenceNumber,
  write: (selectedReferenceNumber) => ({ selectedReferenceNumber }),
});

export const redirectView = store.createView({
  name: 'post-login redirect url',
  read: (state) => state.redirectUrl,
  write: (redirectUrl) => ({ redirectUrl }),
});

export const accountsInformationView = store.createView({
  name: 'account full information',
  read: (state) => state.accountsInformation,
  write: (accountsInformation) => ({ accountsInformation }),
});

// account state

export const accountView = store.createView({
  name: 'selected account',
  read: (state) => state.account,
  write: (account) => ({ account }),
});

export const paymentHistoryView = store.createView({
  name: 'payment history',
  read: (state) => state.paymentHistory,
  write: (paymentHistory) => ({ paymentHistory }),
});

export const activePaymentPlansView = store.createView({
  name: 'active payment plans',
  read: (state) => state.activePaymentPlans,
  write: (activePaymentPlans) => ({ activePaymentPlans }),
});

export const documentsView = store.createView({
  name: 'documents',
  read: (state) => state.documents,
  write: (documents) => ({ documents }),
});

// landing page state

export const shortLinkRedirectView = store.createView({
  name: 'shortlink full url',
  read: (state) => state.shortLink,
  write: (shortLink) => ({ shortLink }),
});

export const defaultSpecialPlanView = store.createView({
  name: 'default special plan',
  read: (state) => state.defaultSpecialPlan,
  write: (defaultSpecialPlan) => ({ defaultSpecialPlan }),
});

export const settlementOffersView = store.createView({
  name: 'settlement offers',
  read: (state) => state.settlementOffers,
  write: (settlementOffers) => ({ settlementOffers }),
});

// new payment plan stuff

export const paymentPlanView = store.createView({
  name: 'payment plan',
  read: (state) => state.paymentPlan,
  write: (paymentPlan) => ({ paymentPlan }),
});

export const paymentMethodView = store.createView({
  name: 'saved payment method',
  read: (state) => state.paymentMethod,
  key: ({ token }) => token,
  write: (paymentMethod) => ({ paymentMethod }),
});

export const paymentStartDateView = store.createView({
  name: 'payment start date',
  read: (state) => state.paymentStart,
  write: (paymentStart) => ({ paymentStart }),
});

export const menuToggledView = store.createView({
  name: 'menu toggled',
  read: (state) => state.menuToggled,
  write: (menuToggled) => ({ menuToggled }),
});

export const negotiatedPlanView = store.createView({
  name: 'negotiated payment plan',
  read: (state) => state.negotiatedPlan,
  write: (negotiatedPlan) => ({ negotiatedPlan }),
});

export const selectedPlanTypeView = store.createView({
  name: 'selected plan type',
  read: (state) => state.selectedPlanType,
  write: (selectedPlanType) => ({ selectedPlanType }),
});

export const splashPageView = store.createView({
  name: 'comes from splash page',
  read: (state) => state.comesFromSplashPage,
  write: (comesFromSplashPage) => ({ comesFromSplashPage }),
});

export const frictionlessPageView = store.createView({
  name: 'comes from frictionless page',
  read: (state) => state.comesFromFrictionlessPage,
  write: (comesFromFrictionlessPage) => ({ comesFromFrictionlessPage }),
});

export const selectedStepView = store.createView({
  name: 'selected step',
  read: (state) => state.selectedStep,
  write: (selectedStep) => ({ selectedStep }),
});

// set reference in state, depending on internal redirects
export const referenceView = store.createView({
  name: 'reference',
  read: (state) => state.reference,
  write: (reference) => ({ reference }),
});

export const accountSwitchView = store.createView({
  name: 'account switched',
  read: (state) => state.accountSwitched,
  write: (accountSwitched) => ({ accountSwitched }),
});
