import React, { useMemo, useState } from 'react';

import { useLocale, useStateView } from '../../hooks';
import {
  accountsInformationView,
  redirectView,
  referenceNumbersView,
  selectedReferenceNumberView,
} from '../../state/views';

import { paths } from '../../routes';

import { Button } from '../../components';
import {
  selectAccount,
  accountBox,
  title,
  secondaryText,
  small,
  selected,
  extraInfo,
  continueButton,
} from './styles.scss';

import { cn, debugConsole, mixpanel, redirect } from '../../utils';
import allMessages from './messages';
import { MIXPANEL_EVENTS } from '../../constants';

import selectAccountImg from '../../static/images/images/select-account.svg';
import SidePanels from '../../components/SidePanels';

export default () => {
  const [accountsInformation] = useStateView(accountsInformationView);
  const [, setSelectedReferenceNumber] = useStateView(
    selectedReferenceNumberView
  );
  const [redirectUrl, setRedirectUrl] = useStateView(redirectView);

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState();

  const { messages } = useLocale(allMessages);

  const handleChange = (accountId) => {
    setSelectedAccount(accountId);
    setSubmitDisabled(false);
  };

  const handleSubmit = () => {
    setSelectedReferenceNumber(selectedAccount);

    mixpanel.identify(selectedAccount);
    mixpanel.track(MIXPANEL_EVENTS.selectAccount, {
      referenceNumber: selectedAccount,
    });

    // redirect

    setRedirectUrl('');
    debugConsole.log('redirect', redirectUrl, paths.homeDisclosures);
    redirect(redirectUrl || paths.homeDisclosures);
  };

  const sortedAccounts = useMemo(
    () =>
      accountsInformation.sort((a, b) => {
        return a.accountId.localeCompare(b.accountId);
      }),
    [accountsInformation]
  );

  return (
    <SidePanels
      leftPanelHeader={messages.journey}
      leftPanelImage={selectAccountImg}
      leftPanelImgAlt={messages.leftPanelImgAlt}
      rightPanelHeader={messages.subheading}
    >
      <p>{messages.body}</p>
      {sortedAccounts.map((sortedAccount) => {
        const id = `referenceNumber-${sortedAccount.accountId}`;
        const isSelected = selectedAccount === sortedAccount.accountId;

        return (
          <Button
            aria-label={id}
            className={`${accountBox}  ${isSelected ? selected : ''}`}
            key={id}
            onClick={() => handleChange(sortedAccount.accountId)}
          >
            <div className={`${secondaryText} ${small}`}>
              #{sortedAccount.accountId}
            </div>
            <div className={title}>{sortedAccount.clientName}</div>
            {sortedAccount.storeBrand && (
              <div className={cn(secondaryText, extraInfo)}>
                {messages.storeBrand}:{' '}
                <strong>{sortedAccount.storeBrand}</strong>
              </div>
            )}
            {sortedAccount.currentBalance && (
              <div className={cn(secondaryText, extraInfo)}>
                {messages.currentBalance}:{' '}
                <strong>${sortedAccount.currentBalance}</strong>
              </div>
            )}
          </Button>
        );
      })}
      <Button
        className={continueButton}
        variant={['full', 'large', 'primary']}
        disabled={submitDisabled}
        onClick={handleSubmit}
      >
        {messages.continue}
      </Button>
    </SidePanels>
  );
};
