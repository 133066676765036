import React, { useState } from 'react';
import {
  accordion,
  accordionHeader,
  accordionContent,
  arrow,
  arrowOpen,
} from './styles.scss';
import DropdownArrow from '../../static/images/icons/DropdownArrow';
import { cn } from '../../utils';

function Accordion({ title, forceOpen, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={accordion}>
      <div className={accordionHeader} onClick={toggleAccordion}>
        <span>{title}</span>
        {!forceOpen && (
          <span className={cn(arrow, isOpen && arrowOpen)}>
            <DropdownArrow />
          </span>
        )}
      </div>
      {(isOpen || forceOpen) && (
        <div className={accordionContent}>{children}</div>
      )}
    </div>
  );
}

export default Accordion;
