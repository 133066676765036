import React from 'react';
import { string, node } from 'prop-types';
import { Link } from 'react-router-dom';

import headerLogoSrc from '../../static/images/icons/logoIcon.svg';
import headerSmallLogoSrc from '../../static/images/icons/logoMinIcon.svg';

import { paths } from '../../routes';

import {
  wrapper,
  leftPanel,
  logo,
  leftPanelImgClass,
  rightPanel,
  logoWrapper,
  logoSmall,
  link,
} from './styles.scss';
import LanguageSelect from '../LanguageSelect';
import { useStateView } from '../../hooks';
import { selectedReferenceNumberView } from '../../state/views';

const SidePanels = ({
  leftPanelHeader,
  rightPanelHeader,
  leftPanelImage,
  leftPanelImgAlt,
  children,
}) => {
  const [selectedReferenceNumber] = useStateView(selectedReferenceNumberView);

  return (
    <div className={wrapper}>
      <div className={leftPanel}>
        <Link
          data-mp-name='Header Logo'
          to={selectedReferenceNumber ? paths.home : {}}
        >
          <img
            className={logo}
            src={headerLogoSrc}
            alt='Spring Oaks Capital logo'
          />
        </Link>
        <h1>{leftPanelHeader}</h1>
        <img
          className={leftPanelImgClass}
          src={leftPanelImage}
          alt={leftPanelImgAlt}
        />
      </div>
      <div className={rightPanel}>
        <div className={logoWrapper}>
          <Link data-mp-name='Header Logo' to={paths.home} className={link}>
            <img
              className={logo}
              src={headerLogoSrc}
              alt='Spring Oaks Capital main logo'
            />
            <img
              className={logoSmall}
              src={headerSmallLogoSrc}
              alt='Spring Oaks Capital small logo'
            />
          </Link>
          <LanguageSelect />
        </div>
        <h2>{rightPanelHeader}</h2>
        {children}
      </div>
    </div>
  );
};

SidePanels.propTypes = {
  leftPanelHeader: string,
  rightPanelHeader: string,
  leftPanelImage: string,
  leftPanelImgAlt: string,
  children: node,
};

export default SidePanels;
