import React, { useState } from 'react';

import {
  stepper,
  planTag,
  paymentMethodWrapper,
  paymentButtons,
  detailsWrapper,
} from '../styles.scss';
import { redirect } from '../../../utils';

import allMessages from '../messages';
import { useLocale, useStateView } from '../../../hooks';
import { paths } from '../../../routes';
import PaymentFrequencyMessage from '../../../components/PaymentFrequencyMessage';
import {
  PaymentMethodForm,
  PlanDetailBox,
  ProgressBar,
} from '../../../components';
import { VARIANTS } from '../../../components/ProgressBar';
import TableCard from '../../../components/TableCard';
import { shortLinkRedirectView } from '../../../state/views';

const PaymentMethodStep = ({
  referenceNumber,
  paymentPlan,
  selectedPlanType,
  currentBalance,
}) => {
  const [progressValue, setProgressValue] = useState(25);
  const { messages, formatDate } = useLocale(allMessages);
  const [shortLink] = useStateView(shortLinkRedirectView);

  const isFullPayment = paymentPlan.numPayments === 1;

  const { numPayments, paymentStartDate, paymentSchedule } = paymentPlan;

  let upcomingPayment = '';
  let lastPayment = '';

  if (paymentSchedule.length && paymentStartDate) {
    const startDateFormat =
      numPayments === 1
        ? { month: 'long', day: 'numeric' }
        : { month: 'long', day: 'numeric', year: 'numeric' };

    const startDatePayment = formatDate(paymentStartDate, startDateFormat);

    upcomingPayment = (
      <>
        <strong>{`1 ${messages.of} ${numPayments}`}</strong>
        {` (${messages.dueOn} `}
        <strong>{startDatePayment}</strong>
        {')'}
      </>
    );

    lastPayment = (
      <strong>
        {formatDate(paymentSchedule.at(-1).date, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })}
      </strong>
    );
  }
  const accountDetails = [
    ...(shortLink?.cardStoreBrand && [
      {
        label: messages.brandName,
        value: shortLink.cardStoreBrand,
      },
    ]),
    {
      label: messages.originalCreditor,
      value: shortLink?.clientName || messages.notAvailable,
    },
    { label: messages.referenceNumber, value: referenceNumber },
  ];

  return (
    <>
      <div className={stepper}>
        <ProgressBar value={progressValue} variant={VARIANTS.newStyle} />
      </div>

      <div className={planTag}>{messages[selectedPlanType]}</div>
      <h1>{messages.paymentMethod.title}</h1>
      <p>{messages.paymentMethod.subtitle}</p>

      <div className={paymentMethodWrapper}>
        <PaymentMethodForm
          className={paymentButtons}
          accountId={referenceNumber}
          onSubmit={() => redirect(paths.paymentConfirm)}
          callbackFunction={() => setProgressValue(50)}
        />
        <div className={detailsWrapper}>
          <PlanDetailBox
            planType={messages[selectedPlanType]}
            referenceNumber={referenceNumber}
            paymentPlan={paymentPlan}
            paymentFrequency={<PaymentFrequencyMessage />}
            fullPayment={isFullPayment}
            upcomingPayment={upcomingPayment}
            lastPayment={lastPayment}
            isFrictionlessLandingPage
            currentBalance={currentBalance}
          />
          <TableCard
            title={messages.paymentMethod.accountDetails}
            data={accountDetails}
          />
        </div>
      </div>
    </>
  );
};

export default PaymentMethodStep;
