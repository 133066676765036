import React, { useEffect } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { paths } from '../../routes';

import { useLocale, useStateView } from '../../hooks';
import {
  accountView,
  menuToggledView,
  referenceNumbersView,
} from '../../state/views';

import { logout, mixpanel } from '../../utils';
import allMessages from './messages';

import {
  navbarContainer,
  navbarLogout,
  navbarOverlay,
  logo,
  infoRow,
  userInfo,
  backdrop,
  toggled,
  referenceTag,
} from './styles.scss';

import avatarIconSrc from '../../static/images/icons/avatarIcon.svg';
import logoutIconSrc from '../../static/images/icons/logoutIcon.svg';

import { MIXPANEL_EVENTS } from '../../constants';
import HomeIcon from '../../static/images/icons/HomeIcon.js';
import PaymentsIcon from '../../static/images/icons/PaymentsIcon.js';
import DisclosureIcon from '../../static/images/icons/DisclosureIcon.js';
import AccountSelect from '../AccountSelect/index.js';
import PaymentsHistoryIcon from '../../static/images/icons/PaymentsHistoryIcon.js';
import DocumentsIcon from '../../static/images/icons/DocumentsIcon.js';

const NavBar = () => {
  const [{ accountId, accountholderFirstName, accountholderLastName }] =
    useStateView(accountView);

  const [referenceNumbers] = useStateView(referenceNumbersView);
  const [menuToggled, setMenuToggled] = useStateView(menuToggledView);

  const { messages } = useLocale(allMessages);

  const isActive = (path) => useMatch(path);

  const getLinkProps = (path) => ({
    // checks if the current URL matches the given route path and if
    // it does, emits a data-attribute property we can select with CSS
    'data-active-route': useMatch(path),
    to: path,
    onClick: () => setMenuToggled(false),
  });

  const trackNavbarToggle = () => {
    const eventName = menuToggled
      ? MIXPANEL_EVENTS.openSideMenu
      : MIXPANEL_EVENTS.closeSideMenu;

    mixpanel.track(eventName);
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      const navBar = document.getElementById('navbar');
      const backdrop = document.getElementById('backdrop');
      if (menuToggled) {
        backdrop.classList.add(toggled);
        navBar.classList.add(toggled);
      } else {
        backdrop.classList.remove(toggled);
        navBar.classList.remove(toggled);
      }

      trackNavbarToggle();
    }
  }, [menuToggled, window]);

  return (
    <>
      <div
        className={navbarOverlay}
        id='navbar'
        onClick={() => setMenuToggled(false)}
      >
        <nav className={navbarContainer} onClick={(e) => e.stopPropagation()}>
          <div className={`${infoRow} ${userInfo}`}>
            <img src={avatarIconSrc} className={logo} alt='Name' />
            <div>
              <h6>
                {accountholderFirstName} {accountholderLastName}
              </h6>
              {referenceNumbers.length > 1 ? (
                <AccountSelect />
              ) : (
                <span className={referenceTag}>#{referenceNumbers[0]}</span>
              )}
            </div>
          </div>
          <Link className={infoRow} {...getLinkProps(paths.home)}>
            <span>
              <HomeIcon active={isActive(paths.home)} />
            </span>{' '}
            {messages.home}
          </Link>
          <Link className={infoRow} {...getLinkProps(paths.paymentPlan)}>
            <span>
              <PaymentsIcon active={isActive(paths.paymentPlan)} />
            </span>{' '}
            {messages.payments}
          </Link>
          <Link className={infoRow} {...getLinkProps(paths.paymentHistory)}>
            <span>
              <PaymentsHistoryIcon active={isActive(paths.paymentHistory)} />
            </span>{' '}
            {messages.paymentHistory}
          </Link>
          <Link className={infoRow} {...getLinkProps(paths.documents)}>
            <span>
              <DocumentsIcon active={isActive(paths.documents)} />
            </span>{' '}
            {messages.documents}
          </Link>
          <Link className={infoRow} {...getLinkProps(paths.homeDisclosures)}>
            <span>
              <DisclosureIcon active={isActive(paths.homeDisclosures)} />
            </span>{' '}
            {messages.disclosures}
          </Link>
          <div className={navbarLogout}>
            <Link onClick={logout}>
              <span>{messages.logout}</span>
              <img src={logoutIconSrc} className={logo} alt='' />
            </Link>
          </div>
        </nav>
      </div>
      <div id='backdrop' className={backdrop} />
    </>
  );
};

export default NavBar;
