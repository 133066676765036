import React from 'react';
import ResumedPaymentSummary from '../ResumedPaymentSummary';
import {
  detailBox,
  wrapper,
  infoRow,
  separator,
  planTypeTag,
  balance,
  webDisplay,
  mobileDisplay,
  alignRight,
  firstPaymentRow,
  lastPaymentRow,
  balanceRow,
  rightColumn,
  paymentList,
  standardPaymentAmountColumn,
  balancesColumn,
} from './styles.scss';
import {
  cn,
  formatCurrency,
  splitLastPayment,
  splitUpcomingPayment,
} from '../../utils';
import { useLocale, useStateView } from '../../hooks';
import { PAYMENT_PLAN_TYPES } from '../../constants';
import { selectedStepView } from '../../state/views';
import Accordion from '../Accordion';

const PlanDetailBox = ({
  referenceNumber,
  planType,
  paymentPlan,
  currentBalance,
  upcomingPayment,
  lastPayment,
  paymentFrequency,
  isSuccessPage,
  isCustomPlan = false,
  selectedAmount,
  fullPayment,
  withCurrentBalance,
  isConfirmPage,
  showFrequency,
  telLink,
  isFrictionlessLandingPage,
}) => {
  const { messages, formatDate } = useLocale();
  const [currentStep] = useStateView(selectedStepView);

  const isPartialPayment =
    paymentPlan.planType === PAYMENT_PLAN_TYPES.paymentPartial &&
    (isConfirmPage || isSuccessPage);
  const showCurrentBalance =
    (!fullPayment || (fullPayment && withCurrentBalance)) &&
    !isPartialPayment &&
    currentBalance;
  const showReferenceNumber = !!referenceNumber;
  const hasPaymentsMessages = !!upcomingPayment && !!lastPayment;

  const fullPaymentTitle =
    currentStep !== 'setup' ? messages.fullBalance : messages.payInFull;

  const isFullPayment =
    paymentPlan.planType === PAYMENT_PLAN_TYPES.payInFull ||
    paymentPlan.planType === PAYMENT_PLAN_TYPES.settleInFull;

  const isSettlement =
    paymentPlan.planType === PAYMENT_PLAN_TYPES.settlementPlan ||
    paymentPlan.planType === PAYMENT_PLAN_TYPES.settleInFull;

  const title = isFrictionlessLandingPage
    ? isFullPayment
      ? messages.paymentDetails
      : messages.planDetail
    : fullPayment
    ? fullPaymentTitle
    : messages.planDetail;

  const amountToSettle = paymentPlan.paymentSchedule?.reduce(
    (acc, payment) => acc + payment.amount,
    0
  );

  return (
    <div className={detailBox}>
      <div className={wrapper}>
        {showReferenceNumber ? (
          <>
            <div className={infoRow}>
              <h5>#{referenceNumber}</h5>
              <span className={planTypeTag}>{planType}</span>
            </div>
            <h2>{title}</h2>
          </>
        ) : (
          <>
            <div className={cn(separator, infoRow)}>
              <h2>{messages.planDetail}</h2>
              <span className={planTypeTag}>{planType}</span>
            </div>
          </>
        )}

        {isFrictionlessLandingPage ? (
          <>
            {isFullPayment ? (
              isConfirmPage || isSuccessPage ? (
                <div className={cn(infoRow, firstPaymentRow, separator)}>
                  <p>Upcoming payment {upcomingPayment}</p>
                  <p
                    className={cn(
                      rightColumn,
                      balancesColumn,
                      standardPaymentAmountColumn
                    )}
                  >
                    ${' '}
                    <strong>{formatCurrency(amountToSettle, '', true)}</strong>
                  </p>
                </div>
              ) : (
                <div
                  className={cn(
                    infoRow,
                    firstPaymentRow,
                    !isSettlement && separator
                  )}
                >
                  <p>Upcoming payment {upcomingPayment}</p>
                </div>
              )
            ) : isConfirmPage || isSuccessPage ? (
              <>
                <div
                  className={cn(
                    infoRow,
                    firstPaymentRow,
                    separator,
                    webDisplay
                  )}
                >
                  <h5>
                    {isConfirmPage
                      ? messages.firstPayment
                      : messages.upcomingPayment}{' '}
                    {upcomingPayment}
                  </h5>
                  <p className={rightColumn}>
                    {messages.lastPayment} {lastPayment}
                  </p>
                </div>
                <div className={cn(infoRow, firstPaymentRow, mobileDisplay)}>
                  <h5>
                    {isConfirmPage
                      ? messages.firstPayment
                      : messages.upcomingPayment}
                  </h5>
                  <p className={rightColumn}>{upcomingPayment}</p>
                </div>
                <div
                  className={cn(
                    infoRow,
                    lastPaymentRow,
                    separator,
                    mobileDisplay
                  )}
                >
                  <h5>{messages.lastPayment}</h5>
                  <p className={rightColumn}>{lastPayment}</p>
                </div>
              </>
            ) : (
              <>
                <div className={cn(infoRow, firstPaymentRow)}>
                  <h5>{messages.firstPayment}</h5>
                  <p className={rightColumn}>{upcomingPayment}</p>
                </div>
                <div
                  className={cn(
                    infoRow,
                    lastPaymentRow,
                    !isSettlement && separator
                  )}
                >
                  <h5>{messages.lastPayment}</h5>
                  <p className={rightColumn}>{lastPayment}</p>
                </div>
              </>
            )}
            {isSettlement && !(isConfirmPage || isSuccessPage) && (
              <>
                <div className={cn(infoRow, lastPaymentRow, separator)}>
                  <h5>{messages.resolveBalance}</h5>
                  <p
                    className={cn(
                      rightColumn,
                      balancesColumn,
                      standardPaymentAmountColumn
                    )}
                  >
                    ${' '}
                    <strong>{formatCurrency(amountToSettle, '', true)}</strong>
                  </p>
                </div>
                <div
                  className={cn(infoRow, balanceRow, isSettlement && separator)}
                >
                  <h5>{messages.currentBalance}</h5>
                  <p className={cn(rightColumn, balancesColumn)}>
                    ${' '}
                    <strong>{formatCurrency(currentBalance, '', true)}</strong>
                  </p>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {showCurrentBalance && (
              <div
                className={cn(infoRow, hasPaymentsMessages ? null : separator)}
              >
                <h5>{messages.currentBalance}</h5>
                <div className={balance}>
                  <span>
                    ${' '}
                    <strong>{formatCurrency(currentBalance, '', true)}</strong>
                  </span>
                </div>
              </div>
            )}
            {hasPaymentsMessages && (
              <>
                <div className={cn(infoRow, separator, webDisplay)}>
                  <h5>
                    {fullPayment ? (
                      isSuccessPage || isConfirmPage ? (
                        upcomingPayment
                      ) : (
                        splitUpcomingPayment(upcomingPayment)[0]
                      )
                    ) : (
                      <>
                        {splitUpcomingPayment(upcomingPayment)[0]}{' '}
                        <strong>
                          {splitUpcomingPayment(upcomingPayment)[1]}
                        </strong>
                      </>
                    )}
                  </h5>
                  {(isConfirmPage || isSuccessPage) &&
                  (fullPayment ||
                    paymentPlan.planType ===
                      PAYMENT_PLAN_TYPES.paymentPartial) ? (
                    <div className={balance}>
                      <span>
                        ${' '}
                        <strong>
                          {formatCurrency(currentBalance, '', true)}
                        </strong>
                      </span>
                    </div>
                  ) : (
                    <h5 className={alignRight}>
                      {fullPayment ? (
                        splitUpcomingPayment(upcomingPayment)[1]
                      ) : (
                        <>
                          {splitLastPayment(lastPayment)[0]}{' '}
                          <strong>{splitLastPayment(lastPayment)[1]}</strong>
                        </>
                      )}
                    </h5>
                  )}
                </div>
                <div
                  className={cn(
                    infoRow,
                    mobileDisplay,
                    fullPayment || isPartialPayment ? separator : null
                  )}
                >
                  <h5>{splitUpcomingPayment(upcomingPayment)[0]}</h5>
                  <h5 className={alignRight}>
                    <strong>{splitUpcomingPayment(upcomingPayment)[1]}</strong>
                  </h5>
                </div>
                {!fullPayment && !isPartialPayment && (
                  <div className={cn(infoRow, mobileDisplay, separator)}>
                    <h5>{splitLastPayment(lastPayment)[0]}</h5>
                    <h5>
                      <strong>{splitLastPayment(lastPayment)[1]}</strong>
                    </h5>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>

      {isFrictionlessLandingPage ? (
        (isConfirmPage || isSuccessPage) && isFullPayment ? (
          <ResumedPaymentSummary
            paymentPlan={paymentPlan}
            frequencyMessage={paymentFrequency}
            isSuccessPage={isSuccessPage}
            isCustomPlan={isCustomPlan}
            selectedAmount={selectedAmount}
            fullPayment={fullPayment}
            showFrequency={showFrequency}
            telLink={telLink}
          />
        ) : (
          <Accordion
            title={messages.paymentBreakdown}
            forceOpen={isSuccessPage}
          >
            <ul className={paymentList}>
              {paymentPlan.paymentSchedule.map((payment) => (
                <li key={payment.date}>
                  <span>
                    {formatDate(payment.date, {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </span>
                  <span>
                    ${' '}
                    <strong>{formatCurrency(payment.amount, '', true)}</strong>
                  </span>
                </li>
              ))}
            </ul>
          </Accordion>
        )
      ) : (
        <ResumedPaymentSummary
          paymentPlan={paymentPlan}
          frequencyMessage={paymentFrequency}
          isSuccessPage={isSuccessPage}
          isCustomPlan={isCustomPlan}
          selectedAmount={selectedAmount}
          fullPayment={fullPayment}
          showFrequency={showFrequency}
          telLink={telLink}
        />
      )}
    </div>
  );
};

export default PlanDetailBox;
