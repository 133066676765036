import React, { useState } from 'react';
import { bool, node, object, oneOfType, string } from 'prop-types';
import { cn } from '../../utils';
import { expander, expanderLabel } from './styles.scss';
import DropdownArrow from '../../static/images/icons/DropdownArrow';

const Expander = ({
  label,
  expanded = false,
  className,
  children,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  return (
    <div className={cn(expander, className)} {...props}>
      <div
        className={expanderLabel}
        onClick={() => setIsExpanded(!isExpanded)}
        data-expanded={isExpanded}
        tabIndex={0}
        aria-expanded={isExpanded}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setIsExpanded(!isExpanded);
          }
        }}
      >
        {label}
        <DropdownArrow variant />
      </div>
      {isExpanded && children}
    </div>
  );
};

Expander.propTypes = {
  className: oneOfType([string, object]),
  expanded: bool,
  label: node.isRequired,
};

export default Expander;
